<template>
  <div>
    <div class="slk-loader" v-if="isPending">
      <b-spinner label="Loading..." />
    </div>
    <!-- <backlog-nav
      @search="search"
      @filterAssignee="filterAssignee"
      :config="projectConfig"
      v-if="!activeEpic"
    ></backlog-nav>
     
   -->
    <!-- Searchn Input -->
    <div class="d-flex flex-row-reverse">
      <b-input-group class="input-group-merge ys-search-input mr-2" size="sm">
        <b-form-input placeholder="Поиск... " v-model="search" />
        <b-input-group-append is-text>
          <feather-icon icon="SearchIcon" />
        </b-input-group-append>
      </b-input-group>
    </div>
    <div class="ys-backlog d-flex mt-2">
      <!--
      <backlog-tab v-if="isShowTab" @close="closeTabs" :tabIndex="tabIndex"></backlog-tab>
      
      <epic-tasks v-if="activeEpic"></epic-tasks>
      -->
      <div class="w-100">
        <release-list :releases="releases" />
      </div>
    </div>
  </div>
</template>

<script>
import {
  BSpinner,
  BModal,
  BAlert,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
} from 'bootstrap-vue';

import ReleaseList from './ReleaseList';
import { VBTooltip } from 'bootstrap-vue';
import ModalSprintForm from './ModalSprintForm';

import { mapActions, mapState, mapGetters } from 'vuex';
import { projectApi } from '@/api';

export default {
  name: 'Release',
  components: {
    ReleaseList,
    ModalSprintForm,
    BSpinner,
    BModal,
    BAlert,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  computed: {
    ...mapState('project', [
      'backlogTasks',
      'currentProject',
      'sprints',
      'activeSprintStatus',
      'activeEpic',
      'isPending',
      'releases',
    ]),
    ...mapGetters('project', ['sortSprints']),
  },
  props: {
    searchName: [String],
  },
  data: function () {
    return {
      search: '',
      searchId: null,
      isShowTab: false,
      filterTasks: null,
      filterSprints: null,
      projectConfig: null,
      editSprint: null,
      tabIndex: 1,
    };
  },
  methods: {
    ...mapActions('project', [
      'getBacklogTasks',
      'getSprints',
      'getActiveSprintStatus',
      'getReleases',
      'updatePending',
    ]),
    showTabs(number) {
      this.tabIndex = number;
      this.isShowTab = true;
    },
    closeTabs() {
      this.isShowTab = false;
      this.$store.dispatch('project/updateActiveEpic', null);
    },
    /*
    search(search) {
      if (search.length > 0) {
        this.filterTasks = this.backlogTasks.filter((item) => {
          return (
            item.key.toLowerCase().includes(search.toLowerCase()) ||
            item.name.toLowerCase().includes(search.toLowerCase())
          );
        });

        // Глубокое копирование массива
        const searchSprint = JSON.parse(JSON.stringify(this.sprints));

        this.filterSprints = searchSprint.map((item) => {
          item.tasks = item.tasks.filter((item) => {
            return (
              item.key.toLowerCase().includes(search.toLowerCase()) ||
              item.name.toLowerCase().includes(search.toLowerCase())
            );
          });
          return item;
        });
      } else {
        this.filterTasks = this.backlogTasks;
        this.filterSprints = this.sprints;
      }
    },
    */
    loadData() {
      // this.$store.dispatch('project/updatePending', true);
      /*return axios
        .all([this.getRelease()])
        .then(() => {
          // this.filterTasks = this.backlogTasks;
          // this.filterSprints = this.sprints;
        })
        .catch((error) => {
          this.$bvToast.toast(`Ошибка при загрузке данных.`, {
            title: this.$t('Data Update'),
            variant: 'danger',
            solid: true,
          });
        })
        .then(() => this.$store.dispatch('project/updatePending', false));
        
      projectApi.getConfig(this.currentProject).then((res) => {
        this.projectConfig = res.data.project_config;
      });*/
      console.log('searchName:', this.searchName);
      const arg = this.searchName ? { search: this.searchName } : { page: 1 };
      this.getReleases(arg);
    },
  },
  watch: {
    search(val) {
      clearTimeout(this.searchId);
      this.searchId = setTimeout(() => {
        console.log('search:', val);
        this.getReleases({ search: val });
      }, 3000);
    },
  },
  mounted() {
    this.loadData();
  },
};
</script>

<style scoped></style>
