<template>
  <div v-if="date">
    <div class="d-flex ys-tasks__container-date">
      <span class="ys-tasks__title-date">Запланированная дата: </span>
      <div class="ys-tasks__date">
        <span
          class="backlight"
          :class="{
            red: isBefore,
            green: !isBefore,
          }"
          >{{ date.planned_at }}</span
        >
      </div>
    </div>
    <div class="d-flex ys-tasks__container-date">
      <span class="ys-tasks__title-date"> Фактическая дата: </span>
      <div class="ys-tasks__date">
        <span class="backlight">{{ date.finished_at }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { BBadge, BAvatar, VBTooltip } from 'bootstrap-vue';
import moment from 'moment';
import { mapState } from 'vuex';

export default {
  name: 'ReleaseDate',
  components: {
    BBadge,
    BAvatar,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    date: [Object],
    // taskKey: [String],
  },
  data: () => {
    return {
      /*task: {
        name: '',
        key: '',
        epic: { name: '' },
      },*/
    };
  },
  methods: {},
  computed: {
    ...mapState('project', ['colorStatus']),
    isBefore() {
      const p = moment(this.date.planned_at, 'DD.MM.YYYY hh:mm:ss');
      const f = moment(this.date.finished_at, 'DD.MM.YYYY hh:mm:ss');

      const isBefore = p.isBefore(f);
      return isBefore;
    },
  },
};
</script>

<style scoped>
.ys-tasks__container-date {
  flex: 1 1 2rem;
  width: 80%;
}

.ys-tasks__title-date {
  flex: 1 1 1rem;
}

.ys-tasks__date {
  flex: 2 1 2rem;
}

.backlight {
  /* border: 1px solid #ebe9f1; */
  padding: 1px 8px 1px 8px;
  border-radius: 6px;
}
.red {
  background-color: red;
  color: white;
}

.green {
  background-color: green;
  color: white;
}
</style>
