<template>
  <div class="ys-cards__wrap">
    <!--
    <backlog-sprint
      v-if="sprints"
      v-for="sprint in sprints"
      :key="sprint.id"
      :sprint="sprint"
      :activeSprintStatus="activeSprintStatus"
      @openTask="openTask"
    ></backlog-sprint>
    -->
    <div class="ys-cards">
      <b-card>
        <b-card-header>
          <h6>
            Releases <small class="text-muted">{{ releases.total }} шт</small>
          </h6>
          <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" />
          <div>
            <router-link
              v-if="$can('create', 'doc_schema_release')"
              :to="`/project/${currentProject}/releases/new`"
              class="align-items-center"
            >
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                variant="outline-secondary"
                size="sm"
                class="mr-1"
              >
                Создать релиз
              </b-button>
            </router-link>

            <!--
            <b-dropdown
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="outline-secondary"
              size="sm"
              no-caret
              right
            >
              <template #button-content>
                <feather-icon icon="MoreVerticalIcon" size="14" class="text-body cursor-pointer" />
              </template>
              <b-dropdown-group id="dropdown-group-1" header="Всегда показвать">
                <b-dropdown-item> 100 issue </b-dropdown-item>
                <b-dropdown-item> 500 issue </b-dropdown-item>
                <b-dropdown-item> All </b-dropdown-item>
              </b-dropdown-group>
            </b-dropdown>
            -->
          </div>
        </b-card-header>

        <!-- Todo List -->

        <release-item
          v-for="release in releases.list"
          :key="release.id"
          :release="release"
        ></release-item>
      </b-card>

      <!--
      <b-sidebar
        id="sidebar-right"
        bg-variant="white"
        right
        backdrop
        shadow
        class="sidebar-task"
        no-header
        @hidden="hidden"
      >
        <template #default="{ hide }">
          <div class="ys-task__close" @click="hide">
            <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="18" />
          </div>
          <task-aside v-if="activeTask" :task="activeTask"></task-aside>
        </template>
      </b-sidebar>
      -->
    </div>
  </div>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BButton,
  BDropdown,
  BDropdownItem,
  BDropdownGroup,
  BSidebar,
  VBToggle,
  BPagination,
} from 'bootstrap-vue';
import Ripple from 'vue-ripple-directive';
import VuePerfectScrollbar from 'vue-perfect-scrollbar';
import draggable from 'vuedraggable';
import ReleaseItem from './ReleaseItem';
import TaskContent from '../task/TaskContent';

import TaskAside from '../task/TaskAside';
import { mapActions, mapState } from 'vuex';
import { taskApi } from '@/api';
import { updateTask, backlogDnDEnd } from '@/mixins/task';

export default {
  name: 'ReleaseList',
  components: {
    BCard,
    BCardHeader,
    BButton,
    BDropdown,
    BDropdownItem,
    BDropdownGroup,
    draggable,
    VuePerfectScrollbar,
    ReleaseItem,
    BPagination,
    BSidebar,
    TaskContent,
    TaskAside,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  props: {
    releases: [Object],
  },
  mixins: [updateTask, backlogDnDEnd],
  data: () => ({
    perfectScrollbarSettings: {
      maxScrollbarLength: 150,
    },
    currentPage: 1,
    perPage: 5,
    releasesPerPage: [],
  }),
  computed: {
    ...mapState('project', ['currentProject', 'activeTask']),
    rows() {
      return this.releases.total;
    },
  },
  watch: {
    currentPage(val) {
      this.getReleases({ page: val });
    },
  },
  mounted() {},
  methods: {
    ...mapActions('project', ['getReleases', 'updatePending']),
    onEnd(event) {
      this.showMessage(event);
      const { task_number, to } = this.getDnDRes(event);
      this.updateTask(task_number, {
        sprint_uuid: to,
      });
    },
    hidden() {
      this.$store.dispatch('project/updateActiveTask', null);
    },
    openTask(task) {
      taskApi.task(this.currentProject, task.number).then((res) => {
        this.$store.dispatch('project/updateActiveTask', res.data.task);
        this.$root.$emit('bv::toggle::collapse', 'sidebar-right');
      });
    },
  },
};
</script>

<style scoped></style>
