<template>
  <!-- <li class="d-flex flex-column ys-tasks__item mb-1" :data-task="task.version"> -->
  <div class="ys-card__release mb-1">
    <div
      class="ys-tasks__wrapper d-flex ml-0 p-1 align-items-center justify-content-between ys-tasks__main"
    >
      <feather-icon v-if="drag" icon="MoreVerticalIcon" />
      <div
        class="align-items-center ys-tasks__left-container"
        Удалить
        релиз
        v-b-toggle="'collapse-' + release.id"
      >
        <!--
        <feather-icon :icon="iconTask(task.type.name)" :class="colorTask(task.type.name)" />
        -->
        <div class="ml-1 ys-tasks__version">
          <span>{{ release.version }}</span>
        </div>
        <div class="ml-1 ys-tasks__start">
          <span>Дата начала: {{ release.started_at }}</span>
        </div>
      </div>

      <div class="d-flex ys-tasks__right-container">
        <div class="d-flex align-items-center">
          <b-dropdown
            v-if="$can('update', 'doc_schema_release')"
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
            size="sm"
            no-caret
            right
          >
            <template #button-content>
              <feather-icon icon="MoreVerticalIcon" size="14" class="text-body cursor-pointer" />
            </template>
            <b-dropdown-group id="dropdown-group-1">
              <b-dropdown-item @click="editRelease"> Редактировать релиз </b-dropdown-item>
              <b-dropdown-item @click="generateDoc"> Сформировать документацию </b-dropdown-item>
            </b-dropdown-group>
          </b-dropdown>
        </div>
      </div>
    </div>
    <b-collapse :id="releaseID" :visible="false">
      <div class="m-2">
        <div v-if="release.started_at" class="ml-1 ys-tasks__start">
          <span>Дата начала: {{ release.started_at }}</span>
        </div>
        <div v-if="release.deadline" class="ml-1 mt-1">
          <p>Внутренний deadline задач:</p>
          <div class="ml-2">
            <release-date-item :date="release.deadline" />
          </div>
        </div>
        <div v-if="release.qa" class="ml-1 mt-1">
          <p>Тестирование готового функционала:</p>
          <div class="ml-2">
            <release-date-item :date="release.qa" />
          </div>
        </div>
        <div v-if="release.rc" class="ml-1 mt-1">
          <p>Готовность RC:</p>
          <div class="ml-2">
            <release-date-item :date="release.rc" />
          </div>
        </div>
        <div v-if="release.deploy" class="ml-1 mt-1">
          <p>Release:</p>
          <div class="ml-2">
            <release-date-item :date="release.deploy" />
          </div>
        </div>
        <div v-if="release.status" class="ml-1 mt-1">
          <div class="d-flex ys-tasks__container-status">
            <span class="ys-tasks__title-date mr-2">Статус:</span>
            <div class="ys-tasks__status-name">
              <div class="status-width">
                <span class="backlight" v-bind:style="{ background: colorStatus, color: '#fff' }">{{
                  release.status.name
                }}</span>
              </div>
            </div>
          </div>
        </div>
        <div v-if="release.epics" class="ml-1 mt-1">
          <p>epics:</p>
          <div class="ml-2">
            <div class="d-flex ys-tasks__container-tasks" v-for="epic in release.epics">
              <epic-item :task="epic" type="epic" />
              <!-- 
              <span class="ys-tasks__title-date">{{ epic.key }}</span
              ><span class="ys-tasks__date">sadasd</span>
           -->
            </div>
          </div>
        </div>
        <div v-if="release.tasks" class="ml-1 mt-1">
          <p>tasks:</p>
          <div class="ml-2">
            <div class="d-flex ys-tasks__container-tasks" v-for="task in release.tasks">
              <epic-item :task="task" type="task"/>
              <!-- 
              <span class="ys-tasks__title-date">{{ epic.key }}</span
              ><span class="ys-tasks__date">sadasd</span>
           -->
            </div>
          </div>
        </div>
      </div>
    </b-collapse>
  </div>
  <!-- </li> -->
</template>

<script>
import {
  BBadge,
  BAvatar,
  BDropdown,
  BDropdownItem,
  BDropdownGroup,
  VBTooltip,
  VBToggle,
  BCollapse,
} from 'bootstrap-vue';
import { colorTask, iconTask } from '@/helpers';
import Ripple from 'vue-ripple-directive';
import { mapState } from 'vuex';
import EpicItem from './EpicItem';
import ReleaseDateItem from './ReleaseDateItem';
import moment from 'moment';

export default {
  name: 'ReleaseItem',
  components: {
    BBadge,
    BDropdown,
    BDropdownItem,
    BDropdownGroup,
    BCollapse,
    EpicItem,
    ReleaseDateItem,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
    'b-toggle': VBToggle,
  },
  props: {
    release: [Object],
    drag: {
      type: Boolean,
      default: true,
    },
    link: {
      type: [Boolean, String],
      default: false,
    },
    lengthName: {
      type: [Boolean, String],
      default: false,
    },
    isLabel: {
      type: Boolean,
      default: true,
    },
  },
  data: function () {
    return {
      colorTask,
      iconTask,
      statusStyle: {
        color: 'white',
        background: 'red',
      },
    };
  },
  methods: {
    openTask() {
      if (this.link) window.open(this.link, '_blank');
    },
    getColor(status) {
      const curStatus = this.colorStatus.filter((item) => item.id == status.id);
      return curStatus.length > 0 ? curStatus[0]['color'] : '#82868b';
    },
    editRelease() {
      this.$router.push({
        // name: 'release',
        path: `/project/${this.currentProject}/releases/${this.release.id}`,
      });
    },
    deleteRelease() {},
    generateDoc() {
      this.$router.push({
        // name: 'release',
        path: `/project/${this.currentProject}/releases/${this.release.id}/doc`,
      });
    },
  },
  computed: {
    ...mapState('project', ['colorStatus', 'currentProject']),
    /*sliceName() {
      if (this.lengthName) {
        return this.release.name.length > this.lengthName
          ? this.release.name.slice(0, this.lengthName) + '...'
          : this.release.name;
      } else {
        return this.release.name;
      }
    },*/

    colorStatus() {
      return `#${this.release.status.color}`;
    },

    releaseID() {
      return 'collapse-' + this.release.id;
    },

    isBefore() {
      return moment(this.release.rc.finished_at).isBefore(moment());
    },
  },
  mounted() {
    // console.log('moment:', moment(this.release.rc.finished_at).isBefore(moment()));
  },
};
</script>

<style scoped>
.ys-tasks__main {
  display: flex;
  min-width: 49rem;
}

.ys-card__release {
  border: 1px solid #ebe9f1;
  padding: 5px 10px 0 5px;
  border-radius: 4px;

  box-shadow: 0 3px 10px 0 #ebe9f1;
  background: #f9f9f9;

  user-select: none;
}

.ys-tasks__left-container {
  display: flex;
  /* background-color: pink; */
  flex: 5 1 0;
}

.ys-tasks__right-container {
  flex: 3 1 4rem;
  align-self: start;
  /*background-color: pink;*/
  justify-content: flex-end;
  color: white;
}

.backlightRed {
  background-color: red;
  color: white;
}

.ys-tasks__version {
  flex: 1 1 3rem;
}

.ys-tasks__start {
  flex: 4 1 8rem;
}

.ys-tasks__container-status {
  flex: 1 1 2rem;
  width: 80%;
}

.ys-tasks__container-tasks {
  flex: 1 1 2rem;
  width: 98%;
}

.ys-tasks__title-date {
  flex: 1 1 1rem;
}

.ys-tasks__date {
  flex: 3 1 2rem;
}

.ys-tasks__status-name {
  flex: 2 1 2rem;
}

.status-width {
  display: flex;
  width: 11rem;
  justify-content: center;
  /* background: pink; */
}

.ys-tasks__bage {
  flex: 1 1 6rem;
  display: flex;
  justify-content: center;
  align-self: start;
  margin-left: 1px;
}

.ys-tasks__base-item {
  flex: 1 1 0;
  margin-left: 2px;
  /* background-color: yellow; */
  display: flex;
  justify-content: center;
}

.ys-tasks__epic {
  flex: 2 1 5rem;
  justify-content: start;
  word-wrap: break-word;
}

.backlight {
  /* border: 1px solid #ebe9f1; */
  padding: 1px 8px 1px 8px;
  border-radius: 6px;
  color: white;
}
</style>
