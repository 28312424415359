<template>
  <li class="d-flex tasks__item align-items-center" :data-task="task.number">
    <feather-icon v-if="drag" icon="MoreVerticalIcon" class="d-inline" />
    <div
      class="ys-tasks__wrapper d-flex ml-1 align-items-center justify-content-between ys-tasks__main"
    >
      <div class="align-items-center ys-tasks__left-container">
        <div class="ml-1 ys-tasks__title">{{ computedName }}</div>
      </div>

      <div class="ys-tasks__right-container">
        <div class="ys-tasks__base-item ys-tasks__epic">
          <small v-if="task.epic" class="ml-0">{{ task.epic.name }}</small>
        </div>
        <div class="card-text ys-tasks__base-item ys-tasks__key">
          <router-link class="ml-1" :to="{ name: 'projects-task', params: computedParams }"  target='_blank'>
            {{ task.key }}
          </router-link>
        </div>
      </div>
    </div>
  </li>
</template>

<script>
import { BBadge, BAvatar, VBTooltip } from 'bootstrap-vue';
import { colorTask, iconTask } from '@/helpers';
import { mapState } from 'vuex';

export default {
  name: 'EpicItem',
  components: {
    BBadge,
    BAvatar,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    task: [Object],
    // taskKey: [String],
    drag: {
      type: Boolean,
      default: true,
    },
    link: {
      type: [Boolean, String],
      default: false,
    },
    type: {
      type: String,
      default: '',
    },
  },
  data: function () {
    return {
      colorTask,
      iconTask,
      /*task: {
        name: '',
        key: '',
        epic: { name: '' },
      },*/
    };
  },
  methods: {
    openTask() {
      if (this.link) window.open(this.link, '_blank');
    },
    getColor(status) {
      const curStatus = this.colorStatus.filter((item) => item.id == status.id);
      return curStatus.length > 0 ? curStatus[0]['color'] : '#82868b';
    },
  },
  computed: {
    ...mapState('project', ['colorStatus']),

    computedName() {
      const { name, labels } = this.task;
      return this.type == 'epic'
        ? `${name}`
        : `${name} (${labels?.length ? labels[0].name : 'не задано'})`;
    },
    computedParams() {
      const params = this.task.key.split('-');
      return { project: params[0], task: params[1] };
    },
  },
};
</script>

<style scoped>
.ys-tasks__main {
  display: flex;
  /*min-width: 49rem; */
}

.tasks__item {
  border: 1px solid #ebe9f1;
  padding: 5px 10px 0 5px;
  /* cursor: pointer; */
  transition: all 0.2s;
  background: #fff;
  /*min-width: 52rem; */

  width: 100%;
}

.ys-tasks__left-container {
  display: flex;
  /* background-color: pink; */
  flex: 5 1 0;
}

.ys-tasks__right-container {
  display: flex;
  flex: 3 1 4rem;
  align-self: start;
}

.ys-tasks__title {
  flex: 8 1 8rem;
}

.ys-tasks__bage {
  flex: 0.6 1 4rem;
  display: flex;
  justify-content: center;
  align-self: start;
  margin-left: 1px;
}

.ys-tasks__base-item {
  flex: 1 1 4rem;
  margin-left: 2px;
  /* background-color: yellow; */
  /* display: flex; */
  justify-content: center;
}

.ys-tasks__key {
  /*flex: 1 1 4rem; */
  margin-left: 2px;
  /* background-color: yellow; */
  display: flex;
  justify-content: center;
}

.ys-tasks__epic {
  flex: 3 1 5rem;
  justify-content: start;
  word-wrap: break-word;
}
</style>
