import { render, staticRenderFns } from "./EpicItem.vue?vue&type=template&id=7f756043&scoped=true&"
import script from "./EpicItem.vue?vue&type=script&lang=js&"
export * from "./EpicItem.vue?vue&type=script&lang=js&"
import style0 from "./EpicItem.vue?vue&type=style&index=0&id=7f756043&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7f756043",
  null
  
)

export default component.exports